<template>
  <div id="app">
    <v-app id="inspire" class="custom_theme">
      <router-view />
    </v-app>
  </div>
</template>

<script>
export default {
  name: "Index",
  data: () => ({}),
  props: {},
  methods: {},
  components: {},
  created() {},
};
</script>